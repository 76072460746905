import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { SongRulesData } from "@/data";
import SectionTitle from "@/components/section-title";

const SongRules = ({data,html}) => {
    const { sectionContent, posts } = SongRulesData;
    return (
      <section className="commonSection blogDetails p-0">
        <Container>
        <Row>
          <Col lg={12} className="text-center">
            <SectionTitle data={sectionContent} />
          </Col>
        </Row>
            <div className="tab-content">
                {posts.map((post, index) => {
                    return  (
                        <Row>
                            <Col lg={12}>
                            <div className="wh_choose">
                                <p><b>"{post.title}"</b></p>
                                <p>{post.subTitle}</p>
                                <p>{post.text}</p>
                                <ul>
                                    {post.lists.map(({ item }, index) => (
                                        <li key={index}>
                                        <i className="fa fa-check-square"></i> {item}
                                        </li>
                                    ))}
                                </ul>
                                <p></p>
                                <p>{post.footNote}</p>
                            </div>
                            </Col>
                        </Row>
                    )
                }
                )}
            </div>
        </Container>
      </section>
    );
};
  
  export default SongRules;